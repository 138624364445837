import { TRIP_OR_REQUEST } from '@lib/appconst'
import dashboardService from '@services/dashboard/dashboardService'
import { action, makeObservable, observable } from 'mobx'

class DashboardStore {
  @observable isLoading!: boolean
  @observable totalRevenueByTime: any = []
  @observable totalRevenueDetail: any = []
  @observable totalUserData: any = []
  @observable totalRemovalRequestData: any = []
  @observable removalRequestAveragePrice: any = []
  @observable removalRequestServiceFee: any = []
  @observable removalRequestByStatusTime: any = []
  @observable removalRequestByCancelReason: any = []
  @observable removalRequestByLocation: any = []
  @observable removalRequestChangeByStaff: any = []
  @observable removalRequestChangeToConfirmWaiting: any = []
  @observable averageRatingByCustomer: any = []
  @observable promotionByTime: any = []
  @observable removalRequestByStatus: any = []
  @observable acceptRate: any = []
  @observable partnerAcceptRate: any = []
  @observable partnerCompletedRate: any = []

  constructor() {
    makeObservable(this)
  }

  @action
  async getTotalRevenueData(params) {
    this.isLoading = true
    this.totalRevenueByTime = await dashboardService.getTotalRevenueData(params)
    this.prepareRevenueDetailData()
    this.isLoading = false
  }

  @action
  async getUserData(params) {
    this.isLoading = true
    this.totalUserData = await dashboardService.getUserData(params)
    this.isLoading = false
  }

  @action
  async getTotalRemovalRequest(params) {
    this.isLoading = true
    this.totalRemovalRequestData = await dashboardService.getTotalRemovalRequestData(params)
    this.isLoading = false
  }

  @action
  async getAveragePrice(params) {
    this.isLoading = true
    this.removalRequestAveragePrice = await dashboardService.getAveragePrice(params)
    this.isLoading = false
  }

  @action
  async getRemovalRequestByFee(params) {
    this.isLoading = true
    this.removalRequestServiceFee = await dashboardService.getRemovalRequestByFee(params)
    this.isLoading = false
  }

  @action
  async getRemovalRequestByStatus(params) {
    this.isLoading = true
    this.removalRequestByStatusTime = await dashboardService.getRemovalRequestByStatusAndTime(params)
    this.isLoading = false
  }

  @action
  async getTotalRemovalRequestByCancelledReason(params) {
    this.isLoading = true
    this.removalRequestByCancelReason = await dashboardService.getTotalRemovalRequestByCancelledReason(params)
    this.isLoading = false
  }

  @action
  async getRemovalRequestByLocation(params) {
    this.isLoading = true
    this.removalRequestByLocation = await dashboardService.getRemovalRequestByLocation(params)
    this.isLoading = false
  }

  @action
  async getAverangeRating(params) {
    this.isLoading = true
    this.averageRatingByCustomer = await dashboardService.getAverangeRating(params)
    this.isLoading = false
  }

  @action
  async getPromotionData(params) {
    this.isLoading = true
    this.promotionByTime = await dashboardService.getPromotionData(params)
    this.isLoading = false
  }

  @action
  async getStatisticRequestByStatus(params) {
    this.isLoading = true
    this.removalRequestByStatus = await dashboardService.getRemovalRequestByStatus(params)
    this.isLoading = false
  }

  @action
  async getAcceptRateData(params) {
    this.isLoading = true
    this.acceptRate = await dashboardService.getAcceptRateData(params)
    this.isLoading = false
  }

  @action
  async getParterAcceptRates(params) {
    this.isLoading = true
    this.partnerAcceptRate = await dashboardService.getParterAcceptRates(params)
    this.isLoading = false
  }

  @action
  async exportRequestRateByPartner(params) {
    this.isLoading = true
    await dashboardService.exportRequestRateByPartner(params)
    this.isLoading = false
  }

  @action
  async getPartnerCompletedRate(params) {
    this.isLoading = true
    this.partnerCompletedRate = await dashboardService.getPartnerCompletedRate(params)
    this.isLoading = false
  }

  @action
  async exportPartnerCompletedRate(params) {
    this.isLoading = true
    await dashboardService.exportPartnerCompletedRate(params)
    this.isLoading = false
  }

  @action
  async getRemovalRequestChangeByStaff(params) {
    this.isLoading = true
    this.removalRequestChangeByStaff = await dashboardService.getRemovalRequestChangeByStaff(params)
    this.isLoading = false
  }

  @action
  async getRemovalRequestChangeToConfirmWaiting(params) {
    this.isLoading = true
    this.removalRequestChangeToConfirmWaiting = await dashboardService.getRemovalRequestChangeToConfirmWaiting(params)
    this.isLoading = false
  }

  async prepareDataRemovalRequestByCancelReason(targetApplicationId, tripOrRequestId = TRIP_OR_REQUEST.request) {
    return (this.removalRequestByCancelReason || [])
      .filter((item) => item.TargetApplication === targetApplicationId && item.Module === tripOrRequestId)
      .map((item) => {
        return { name: item.Name, value: item.TotalCount }
      })
  }
  @action
  private prepareRevenueDetailData() {
    const totalRevenueDetail = this.totalRevenueByTime.reduce(
      (data, item) => {
        data.revenue += item.revenue
        data.commission += item.commission
        data.promotion += item.promotion
        return data
      },
      { revenue: 0, commission: 0, promotion: 0 }
    )

    this.totalRevenueDetail = Object.keys(totalRevenueDetail).map((key) => {
      return { title: key, value: totalRevenueDetail[key] }
    })
  }
}

export default DashboardStore
